
























































































































import {
    Component,
    Vue,
    Watch
} from "vue-property-decorator";
import {
    ActionSheet,
    Dialog
} from "vant";
import Mixin from "@/js/mixins";

@Component({
    mixins: [Mixin]
})
export default class Index extends Vue {
    public member = {}; // 用户信息
    public sheet_show = false; // 切换账号选择框是否显示
    public sheet_actions: any[] = []; // 切换账号选择框的列表

    public bankTitle = ''
    public showCode = false

    // 页面返回时重新加载member数据
    @Watch("$route")
    routeChange() {
        if (this.$route.name === "Index") {
            this.$emit("getMember");
        }
    }

    // 判断是否该等级标签（1:店铺，2:区域代理，3:市级代理）
    hasUserType(user_type) {
        let boolean = false;
        this.sheet_actions.forEach(item => {
            if (user_type === item.user_type) {
                boolean = true;
            }
        });
        return boolean;
    }
    // 显示门店选择列表
    showSheet() {
        this.sheet_show = true;
    }
    // 门店选择
    onSelect(item: any, index: number) {
        this.sheet_show = false;
        console.log(item)
        setTimeout(() => {
            if (item.user_type === "1") {
                // 门店账号
                this.$api.href("/home/shop?id=" + item.user_id);
            } else if (
                item.user_type === "2" ||
                item.user_type === "3" ||
                item.user_type === "4"
            ) {
                // 代理账号
                this.$api.href("/home/agent?id=" + item.user_id);
                localStorage.setItem('nowUserId', item.user_id)
            } else if (item.user_type === "6") { //分公司
                this.$api.href("/user/city?id=" + item.user_id);
            } else if (item.user_type === "5") { //分公司业务员
                this.$api.href("/user/city_my?id=" + item.user_id);
            }
        }, 100);
    }
    // 关闭门店选择列表
    onCancel() {
        this.sheet_show = false;
    }
    // 前往申请门店
    toShopApply() {
        let member: any = this.member;
        // 判断是否实名认证过
        if (member.card && member.card.card_id) {
            this.$api.href("/shop/apply");
        } else {
            Dialog.alert({
                title: "提示",
                message: "请先实名认证后再申请店铺"
            }).then(() => {
                this.$api.href("/user/auth");
            });
        }
    }
    // 前往绑定银行卡
    toUserBank() {
        let member: any = this.member;
        // 判断是否有门店或者代理
        if (member.bank && member.bank.bank_id) {
            this.$api.href("/user/bank_info");
        } else {
            this.$api.href("/user/bank");
        }
    }
    // 获取用户信息
    getMember() {
        let _this = this;
        this.$api.request({
            url: "user/member/info",
            success(res) {
                _this.member = res.data;
                _this.setUserLevel(res.data.user);

                // _this.bankTitle = res.data.bank.bank_branch ? '已绑定' : '请完善支行信息'
                if (res.data.bank == null) {
                    _this.bankTitle = '未绑定'
                } else {
                    if (res.data.bank.bank_branch) {
                        _this.bankTitle = '已绑定'
                    } else {
                        _this.bankTitle = '请完善支行信息'
                    }
                }
            }
        });
    }
    // 显示用户的门店列表
    setUserLevel(user: any) {
        let actions: any[] = [];
        user.forEach((item: any, index: number) => {
            let name = item.user_type_name;
            if (item.region_name) {
                name += `（${item.region_name}）`;
            } else if (item.shop) {
                name += `（${item.shop.shop_name}）`;
            }
            actions.push({
                name,
                ...item
            });
        });
        this.sheet_actions = actions;
    }

    beforeMount() {
        this.$on("getMember", () => {
            // 添加一个事件，返回当前页面就执行
            this.member = {};
            this.getMember();
        });
        this.getMember();
        console.log('执行')
    }
}
